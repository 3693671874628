<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'
import ax from 'axios'
export const axios = ax

export default defineComponent({
  setup() {
    const { global } = useComposition();

    return {
      global
    }
  },
  created() {
    axios.get(process.env.VUE_APP_API_ROOT + '/user.php')
      .then(response => {
        this.global.user = response.data;
      })
      .catch(function (error) {

      });

    axios.get('https://carshare.martinrud.dk')
      .then(response => {
        this.global.navigation.pages = response.data.pages;
        this.global.sponsors = response.data.sponsors;
      })
      .catch(function (error) {

      });


  }
})
</script>

<style lang="scss">
@import "./_scss/main.scss";
</style>
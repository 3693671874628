import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        component: () =>
            import('../views/PageTypes/Login.vue')
    },
    {
        path: '/invite/:invite',
        component: () =>
            import('../views/PageTypes/Login.vue')
    },
    {
        path: '/login',
        component: () =>
            import('../views/PageTypes/Login.vue')
    },
    {
        path: '/loginCheck',
        beforeEnter(to, from, next) {
            window.location.href = "https://google.com/contact";
        }
    },
    {
        path: '/kalender',
        component: () =>
            import('../views/PageTypes/Calendar.vue')
    },
    {
        path: '/admin',
        component: () =>
            import('../views/PageTypes/Admin.vue')
    },
    {
        path: '/mine-bookinger',
        component: () =>
            import('../views/PageTypes/MyBookings.vue')
    },
    {
        path: '/booking/:id',
        component: () =>
            import('../views/PageTypes/Booking.vue')
    },
    {
        path: '/booking/opret',
        component: () =>
            import('../views/PageTypes/BookingCreate.vue')
    },
    {
        path: '/:url',
        component: () =>
            import('../views/PageTypes/Page.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
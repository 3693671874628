import { ref } from 'vue'
const global = ref({
  error: {
    error: false,
    code: 0,
    alias: ''
  },
  navigation: {
    navigatedFrom: null,
    pages: {}
  },
  user: {},
  phases: {
    "3": {
      "alias": "StartBigger",
      "class": "notify-red",
      "da_dk": {
        "short": "Start km større end slut km",
        "long": "Afsluttet, men start km større end slut km"
      }
    },
    "5": {
      "alias": "EndedBothMissing",
      "class": "notify-red",
      "da_dk": {
        "short": "Start og slut km mangler",
        "long": "Afsluttet, men start og slut km ikke indtastet"
      }
    },
    "6": {
      "alias": "EndedStartMissing",
      "class": "notify-red",
      "da_dk": {
        "short": "Start km mangler",
        "long": "Afsluttet, men start km ikke indtastet"
      }
    },
    "7": {
      "alias": "EndedEndMissing",
      "class": "notify-red",
      "da_dk": {
        "short": "Slut km mangler",
        "long": "Afsluttet, men slut km ikke indtastet"
      }
    },
    "10": {
      "alias": "ActualStartMissing",
      "class": "notify-red",
      "da_dk": {
        "short": "Start km mangler",
        "long": "Igangværende, men start km ikke indtastet"
      }
    },
    "11": {
      "alias": "Actual",
      "class": "notify-orange",
      "da_dk": {
        "short": "Igangværende"
      }
    },
    "20": {
      "alias": "Upcomming",
      "da_dk": {
        "short": "Kommende"
      }
    },
    "100": {
      "alias": "Closed",
      "da_dk": {
        "short": "Afsluttet"
      }
    }
  },
});

import ax from 'axios'
export const axios = ax

export const useComposition = function () {
  return {
    global
  }
}